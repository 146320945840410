import React, { Component } from 'react';
import styled from 'styled-components';
import BarChart from '../../components/barchart';
import Button from '../../components/button';

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;


const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;


class ImproveSection extends Component {

  render() {
    return (
      <div style={{background: '#C9DDFF00', paddingTop: 128, paddingLeft: 48, paddingRight: 48}}>
        <div style={{ backgroundColor: '#C9DDFF00', borderRadius: 4, padding: 48, marginTop: 24, marginBottom: 192}}>
          <InnerContainer>
            <Col style={{flex: 1}}>
              <BarChart
                style={{height: 300, pointerEvents: 'none', overflow: 'hidden'}}
                data={{
                  labels: ['', '', ''],
                  datasets: [{
                    //barPercentage: 0.5,
                    barThickness: 100,
                    backgroundColor: ['#C9DDFF', '#C9DDFF66', '#C9DDFF33'],
                    borderColor: ['#C9DDFF', '#C9DDFF66', '#C9DDFF33'],
                    borderWidth: 2,
                    data: [5, 9, 10]
                }]
              }}
              />
            </Col>
            <Col style={{flex: 1, alignItems: 'flex-start', marginLeft: 32}}>
              <h1 style={{textAlign: 'left'}}>
                Can we improve your<br/> <span style={{color: '#C9DDFF'}}> energy forecast accuracy?</span>
              </h1>
              <p style={{marginTop: 32, fontSize: 21,  letterSpacing: 0.15, fontWeight: 400, textAlign: 'left'}}>
                Get free backtesting for 10 sites right now.
                <br/>
                <span style={{fontWeight: 600}}>Zero commitment.</span>
              </p>
              <Button text='START BACKTESTING' to='https://dashboard.rebase.energy/auth/signup/trial'/>
            </Col>
          </InnerContainer>
        </div>
      </div>
    );
  }

}

export default ImproveSection;
