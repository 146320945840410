import React from 'react';
import {Bar} from 'react-chartjs-2';

const BarChart = ({data, style, options}) => {
  options = options === undefined ? {} : options;

  return (
    <div style={{...style}}>
    <Bar
      data={data}
      width={style.width}
      height={style.height}
      options={{
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true,
                    fontColor: '#ffffff00',
                },
                gridLines: {
                  color: '#C9DDFF00',
                  zeroLineColor: '#C9DDFF00',
                },
            }],
            xAxes: [{
                gridLines: {
                  color: '#C9DDFF00',
                  zeroLineColor: '#C9DDFF00',
                },
            }]
        }
      }}

    />
  </div>

  )
}

export default BarChart;
